<template>
  <div class="body" @click="detectLogin">
    <Navigation v-show="!route.meta.hideNavFoot" v-if="isRouterActive"/>
    <router-view />
    <Footer v-show="!route.meta.hideNavFoot"/>
  </div>
</template>

<script>
//test
import Navigation from "@/components/Navigation.vue"
import Footer from "@/components/Footer.vue";
import {useRoute} from 'vue-router'

import {onMounted, onUnmounted, ref} from 'vue'
import {ElMessage} from "element-plus";

export default {
  name: 'App',
  components: {
    Navigation,
    Footer
  },
  setup() {
    const isRouterActive = ref(true)
    const route = useRoute()
    const last = ref(new Date().getTime())
    const current = ref(new Date().getTime())
    const period = ref( 60 * 60 * 1000)
    const timer = ref(0)
    function detectLogin() {
      last.value = new Date().getTime()
    }
    function timeOut() {
      current.value = new Date().getTime()
      if (current.value - last.value > period.value && sessionStorage.getItem("token")) {
        ElMessage({
          message: 'Login expired. Logged out.',
          type: 'warning'
        })
        setTimeout(()=>{
          sessionStorage.removeItem("token")
          location.reload()
        }, 800)
      }
    }

    onMounted(()=> {
      timer.value = setInterval(timeOut, 1000)
    })

    onUnmounted(()=> {
      clearInterval(timer.value)
    })
    return{
      route,
      isRouterActive,
      detectLogin
    }
  }
}
</script>

<style>
@import "./assets/font/text.css";

.body {
  margin:0;
  width: 100%;
  padding:0 0 0 0;
  font:12px/1 Poppins-Regular, Arial, Verdana, Helvetica, sans-serif;
  color:#000000;
  background-color: #FFFFFF;
}
ul,li,dl,dd,dt{
  margin:0;
  padding:0;
  list-style-type:none;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
.router-link-active:hover{
  text-decoration: none;
}
.wrapper{
  width:1240px;
  margin: 0 auto;
  /*border: 1px solid;*/
}
img{
  border: 0;
}

.el-table .success-row{
    background-color: var(--el-color-white)
}
.el-table .primary-row{
    background-color: var(--el-color-primary-light-9)
}
.el-table .warning-row{
    background-color: var(--el-color-warning-light-9)
}
.el-table .danger-row{
    background-color: var(--el-color-danger-light-9)
}
</style>
