<template>
  <div class="footer">
    <div class="wrapper">
      <div class="foot-logo">
        <img src="../assets/images/footer-logo01.png">
        <img src="../assets/images/footer-logo02.png">
      </div>
      <div class="foot-team">
        <div class="ft-title"><UserIcon class="i"/>Our Team</div>
        <div class="ft-text">
          <strong>Coordinators: </strong>
          Gedi Luksys, Robin Hill
        </div>
        <div class="ft-text">
          <strong>News selection, annotation, experiments:</strong>
          Jia Wei Tan, Marianna Hronska, Yiyang Zhang, Yining Yang, Maizi Fang, Clara Lopez Velasco, Wenyuan Zhu, Hanyu Li, Kaixuan Li, Puzhi Yu, Yilin Lu, Yanyi Sụn, Lei Zhong, Haiyun Kong, Kim Ngan Linda Nguyen, Jiachen Zheng, Xiaoying Wang, Siyuan Lü, Xinyi Cai
        </div>
        <div class="ft-text">
          <strong>Web development: </strong>
          Ahsun Tariq, Yuhang Tang, Yichen Li, Yuqi Xiao, Debbie Poon, Kungang Li, Zhewen Du, Xinyue Huang, Mariana Martinez Juarez, Jiahe Lyu, Pinhua Wang, Jiachen Cai, Ziyi Zhang, Yiyun Zhu, Jingxian Zhao
        </div>
      </div>
      <div class="foot-contact">
        <div class="fc-item"><font-awesome-icon icon="school" class="i"/>1 George Square <br>Edinburgh, United Kingdom</div>
        <div class="fc-item"><font-awesome-icon icon="phone-volume" class="i"/>+447766706580</div>
        <div class="fc-item"><font-awesome-icon icon="envelope" class="i"/>gedi.luksys@ed.ac.uk<br>ahsun.tariq@gmail.com</div>
      </div>
    </div>
    <div class="foot-copyright">
      <span>© MyNewsScan, 2024</span>
      <div class="privacy"><a href="#">Privacy Policy</a> <a href="#">Terms of Service</a></div>
    </div>
  </div>
</template>

<script>
import {UserIcon} from '@layui/icons-vue'
export default {
  name: "Footer",
  components: {
    UserIcon,
  }
}
</script>

<style scoped>
.footer{
  display: block;
  width: 100%;
  height: 255px;
  padding-top: 30px;
  color: #FFFFFF;
  background-color: #3B5DF5;
}
.footer .wrapper{
  display: flex;
  justify-content: space-between;
}
.foot-logo{
  width: 335px;
}
.foot-logo img{
  margin-bottom: 15px;
}
.foot-logo img:nth-child(1){
  width: 343px;
  height: 72px;
}
.foot-logo img:nth-child(2){
  width: 307px;
  height: 82px;
}
.foot-team{
  width: 355px;
  text-align: center;
}
.foot-team .ft-title{
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  justify-content: center;
}
.foot-team .ft-title .i{
  margin-right: 5px;
}
.foot-team .ft-text{
  font-size: 10px;
  line-height: 1.3;
  margin-top: 10px;
  font-family: "Poppins-Light";
}
.foot-team .ft-text strong{
  display: block;
  font-family: "Poppins-Regular";
}
.foot-contact{
  width: 210px;
  font-size: 10px;
  line-height: 1.3;
  font-family: "Poppins-Light";
}
.foot-contact .fc-item{
  display: flex;
  align-items: center;
  margin:17.5px 0;
}
.foot-contact .fc-item .i{
  margin-right: 12px;
  font-size: large;
}
.foot-copyright{
  width: 1240px;
  margin: 0 auto;
  text-align: center;
  font-size: 12px;
  position: relative;
}
.foot-copyright span{
  padding-left: 130px;
}
.foot-copyright .privacy{
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 10px;
}
.foot-copyright a{
  color: #FFFFFF;
}
</style>