import { createStore } from 'vuex';

const store = createStore({
  state: {
    Authorization:sessionStorage.getItem('Authorization') ? sessionStorage.getItem('Authorization') : ''
  },
  mutations: {
    changeLogin(state, user) {
      console.log("Logging in with Authorization:", user.Authorization);
      state.Authorization = user.Authorization;
      sessionStorage.setItem('Authorization', user.Authorization);
    },
    logout(state) {
      console.log("Logging out");
      state.Authorization = '';
      sessionStorage.removeItem('Authorization');
    }
  }
});

export default store;
