import instance from '../utils/http';

instance.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

export function get(url, params={}, options={}) {
  return new Promise((resolve, reject) => {
    instance.get(url, { params: params }, options)
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function post(url, data={}, options={}) {
  return new Promise((resolve, reject) => {
    instance.post(url, data, options)
      .then(response => {
        if (options.responseType === 'blob') {
          resolve(response);
        } else {
          resolve(response.data);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
}
