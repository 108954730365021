import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import ArcoVue from '@arco-design/web-vue';
import '@arco-design/web-vue/dist/arco.css';
import axiosInstance from './utils/http';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import Layui from '@layui/layui-vue';
import '@layui/layui-vue/lib/index.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

/* Import icons */
import { 
    faSchool, faPhoneVolume, faEnvelope, faXmark, faCircleCheck,
    faCircleXmark, faCoins, faArrowRightFromBracket, faFileCirclePlus,
    faChevronUp, faChevronDown, faCirclePlus, faFilter, faArrowDown,
    faArrowUp, faGraduationCap, faVirus, faHeartPulse, faSeedling,
    faBriefcase, faPalette, faLandmark 
} from '@fortawesome/free-solid-svg-icons';
import { 
    faCircleUser, faEnvelope as regularEnvelop, faPenToSquare,
    faNewspaper, faLightbulb, faChartBar, faCommentDots 
} from '@fortawesome/free-regular-svg-icons';

library.add(
    faSchool, faPhoneVolume, faEnvelope, faCircleUser, faXmark,
    regularEnvelop, faCircleCheck, faCircleXmark, faCoins, faArrowRightFromBracket,
    faPenToSquare, faFileCirclePlus, faChevronUp, faChevronDown, faFilter, faCirclePlus,
    faArrowDown, faArrowUp, faGraduationCap, faVirus, faHeartPulse, faSeedling, faBriefcase,
    faPalette, faLandmark, faNewspaper, faLightbulb, faChartBar, faCommentDots
);

const app = createApp(App);

app.config.globalProperties.$axios = axiosInstance;

app.component('font-awesome-icon', FontAwesomeIcon);
app.use(router);
app.use(store);
app.use(ElementPlus);
app.use(Layui);
app.use(ArcoVue);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}

app.mount('#app');
