<template>
  <div class="header">
    <div class="wrapper">
      <div class="logo">
        <router-link to="/"><img src="../assets/logo.png" /></router-link>
      </div>
      <div class="nav">
        <li>
          <router-link to="/about"
            ><div :class="{ active: navActive.list[0] }" class="nav-item">
              <AboutIcon class="i" /><span>ABOUT US</span>
            </div></router-link
          >
        </li>
        <li>
          <router-link to="/"
            ><div :class="{ active: navActive.list[1] }" class="nav-item">
              <ReadIcon class="i" /> <span>ARTICLES</span>
            </div></router-link
          >
        </li>
        <li>
          <router-link to="/reward"
            ><div :class="{ active: navActive.list[2] }" class="nav-item">
              <EngineIcon class="i" /> <span>REWARD SYSTEM</span>
            </div></router-link
          >
        </li>
        <li>
          <router-link to="/forum"
            ><div :class="{ active: navActive.list[3] }" class="nav-item">
              <ReleaseIcon class="i" /> <span>FORUM</span>
            </div></router-link
          >
        </li>
        <li>
          <el-popover :width="350" v-loading="loading">
            <template #reference>
              <!-- <router-link to="/leaderboard"> -->
                <div :class="{ active: navActive.list[4] }" class="nav-item"><el-icon style="font-size: 23px"><Histogram /></el-icon> <span style="margin-left: 3px">LEADERBOARD</span></div>
              <!-- </router-link> -->
            </template>
            <el-table :data="users" height="400" stripe>
              <el-table-column prop="uname" label="Username"/>
              <el-table-column prop="points" label="Points"/>
            </el-table>
          </el-popover>
        </li>
      </div>
      <div class="login-logout">
        <div v-if="logged" class="user-log-info">
          <div class="user-topinfo">
            <router-link :to="{ path: '/account', query: { uid: user.uid } }">
              <a>
                <div class="ut-face">
                  <img
                    :src="
                      user.profilePath == 'defaultPath'
                        ? require('../assets/images/img-face.png')
                        : 'data:image/png;base64,' + avatar
                    "
                  />
                  <!-- Notification Badge -->
                  <div v-if="unreadMessages > 0" class="notification-badge">
                    {{ unreadMessages > 99 ? '99+' : unreadMessages }}
                  </div>
                </div>
                <div class="ut-text">
                  <div class="ut-name">
                    {{ user.uname }}
                    <el-tag :type="level[user.admin].tag"
                      >Lv.{{ user.admin }} {{ level[user.admin].name }}</el-tag
                    >
                  </div>
                  <div id="html_userpoint" class="ut-integral">
                    <font-awesome-icon
                      icon="coins"
                      style="color: #090970; font-size: 15px; margin-right: 1px"
                    />
                    {{ user.points }}
                  </div>
                </div>
              </a>
            </router-link>
            <div class="ut-logout">
              <div v-if="user.admin > 0" @click="userManage">
                <el-icon
                  style="
                    display: contents;
                    font-size: 15px;
                    margin-right: 10px;
                    font-weight: bold;
                  "
                  ><Avatar
                /></el-icon>
                User Management
              </div>
              <div v-if="user.admin > 1" @click="management">
                <el-icon
                  style="
                    display: contents;
                    font-size: 15px;
                    margin-right: 10px;
                    font-weight: bold;
                  "
                  ><Guide
                /></el-icon>
                Article Management
              </div>
              <div @click="logout">
                <font-awesome-icon
                  icon="arrow-right-from-bracket"
                  style="color: #111880; font-size: 13px; margin-right: 2px"
                />
                Log out
              </div>
            </div>
          </div>
        </div>
        <router-link v-else @click="redirect" to="/login">
          <span class="login"
            ><UsernameIcon class="i" />Sign In / Register
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {
  AboutIcon,
  ReadIcon,
  EngineIcon,
  UsernameIcon,
  ReleaseIcon,
} from "@layui/icons-vue";
import { useRoute } from "vue-router";
import { ref, reactive, watch, onMounted, watchEffect } from "vue";
import { post, get } from "@/api";
import { useRouter } from "vue-router";
import { layer } from "@layui/layui-vue";

export default {
  name: "Navigation",
  components: {
    AboutIcon,
    ReadIcon,
    EngineIcon,
    UsernameIcon,
    ReleaseIcon,
  },
  setup() {
    const loading = ref(true);
    const avatar = ref("");
    const router = useRouter();
    const logged = ref(false);
    const navActive = reactive({
      list: [false, false, false, false],
    });
    const user = ref({});
    const route = useRoute();
    const level = [
      { name: "Regular", tag: "success" },
      { name: "Moderator", tag: "" },
      { name: "Admin", tag: "warning" },
      { name: "Super", tag: "danger" },
    ];
    const users = ref([]);
    const unreadMessages = ref(0); // Unread messages count

    watch(
      () => route.path,
      (to) => {
        checkRoute(to);
        checkLogin();
      }
    );

    function checkLogin() {
      if (sessionStorage.getItem("token")) {
        console.log('check log in have token')
        post(`/user/logged`).then((res) => {
          if (res.res == 1) {
            user.value = res.user;
            if (res.user.profilePath != 'defaultPath') {
              get("/file/download", { fileName: user.value.profilePath }).then(
                (res) => {
                  avatar.value = res.data;
                  console.log("Received Base64 Data: ", res.data);
                }
              );
            }
            logged.value = true;
            fetchUnreadMessages();
          } else {
            console.log('check log in no token')
            sessionStorage.removeItem("token");
            logged.value = false;
          }
        });
      } else {
        logged.value = false;
        user.value = null;
      }
    }

    function fetchUnreadMessages() {
      if (user.value.uid) {
        post("message/get-user-unread-messages-count", { userId: user.value.uid }).then((res) => {
          if (res !== undefined) {
            // console.log(res)
            unreadMessages.value = res;
          } else {
            unreadMessages.value = 0;
          }
        }).catch(() => {
          unreadMessages.value = 0;
        });
      }
    }

    function redirect() {
      // sessionStorage.setItem("redirect", route.fullPath);
    }

    function checkRoute(route) {
      switch (route) {
        case "/about":
          navActive.list = [true, false, false, false, false];
          break;
        case "/":
          navActive.list = [false, true, false, false, false];
          break;
        case "/reward":
          navActive.list = [false, false, true, false, false];
          break;
        case "/forum":
          navActive.list = [false, false, false, true, false];
          break;
        case "/leaderboard":
          navActive.list = [false, false, false, false, true];
          break;
        case "/article":
          navActive.list = [false, true, false, false, false];
          break;
        default:
          navActive.list = [false, false, false, false, false];
      }
    }

    function logout() {
      layer.msg("Logout successfully!", { icon: 1, time: 500 });
      setTimeout(() => {
        logged.value = false;
        user.value = null;
        sessionStorage.removeItem("token");
        sessionStorage.removeItem('Authorization');
        location.reload();
      }, 500);
    }

    function management() {
      router.push({
        path: "/management",
        query: { uid: user.value.uid, admin: user.value.admin },
      });
    }

    function userManage() {
      router.push({
        path: "/user-manage",
        query: { uid: user.value.uid, admin: user.value.admin },
      });
    }

    checkRoute(route.path);
    checkLogin();

    function rank() {
      loading.value = true;
      post(`/user/leaders`).then(res => {
        loading.value = false;
        // Map the data to the desired structure
        users.value = Object.keys(res.leaders).map(username => {
          return { uname: username, points: res.leaders[username] };
        });
      });
    }

    rank();

    // Watch for changes in unreadMessages to fetch them when needed
    watchEffect(() => {
      if (logged.value) {
        fetchUnreadMessages();
      }
    });

    return {
      logged,
      navActive,
      logout,
      redirect,
      user,
      management,
      level,
      userManage,
      avatar,
      users,
      loading,
      unreadMessages
    };
  },
};
</script>

<style scoped>
.header {
  display: block;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #dadada;
}
.header .wrapper {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  height: 70px;
}
.header .wrapper .logo {
  margin-left: 13px;
  width: 205px;
  height: 38px;
}
.header .wrapper .logo img {
  width: 100%;
  height: 100%;
}

.nav {
  display: flex;
  width: auto;
}
.nav li {
  font-size: 14px;
  font-family: "Poppins-Bold";
  margin-right: 35px;
}
.nav li .nav-item {
  display: flex;
  position: relative;
  color: #000000;
  height: 40px;
  align-items: center;
}
.nav li .nav-item .i {
  font-size: 22px;
  margin-right: 5px;
  font-weight: 900;
}
.nav li .nav-item:hover {
  color: #5473ff;
    cursor: pointer;
}
.nav li:hover .nav-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  border-radius: 2.5px;
  background-color: #5473ff;
}
.login-logout {
  width: auto;
}
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 29px;
  font-size: 12px;
  border-radius: 15px;
  text-align: center;
  border: 1px solid #111880;
  font-weight: bold;
  background-color: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
}
.login:hover {
  box-shadow: 0px 2px 20px 0px rgba(59, 93, 245, 0.5);
  font-family: Poppins-Bold;
  color: #5473ff;
  border: 1px solid #5473ff;
}
.login .i {
  font-size: 18px;
  margin-right: 8px;
  font-weight: bolder;
}
.active.nav-item .i,
.active.nav-item span {
  color: #5473ff;
}
.nav li .active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  border-radius: 2.5px;
  background-color: #5473ff;
}
.user-topinfo {
  display: block;
  width: auto;
  position: relative;
}
.user-topinfo a {
  display: flex;
  position: relative;
  z-index: 1001;
}
.user-topinfo .ut-face {
  flex: 0 0 38px;
  width: 38px;
  height: 38px;
  margin-right: 8px;
}
.user-topinfo .ut-face img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid #5473ff;
}
.user-topinfo .ut-face .notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: bold;
}
.user-topinfo:hover .ut-face img {
  border: 2px solid #3b5df5;
}
.user-topinfo .ut-text {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  font-family: "Poppins-SemiBold";
  align-content: center;
  flex-direction: column;
  justify-content: center;
}
.user-topinfo .ut-name {
  font-size: 14px;
  margin-bottom: 6px;
  color: #111880;
}
.user-topinfo .ut-integral {
  font-size: 12px;
  color: #5473ff;
}
.user-topinfo:hover .ut-name,
.user-topinfo:hover .ut-integral {
  color: #3b5df5;
}
.user-topinfo:hover .ut-logout {
  display: block;
}
.user-topinfo .ut-logout {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding-top: 46px;
  height: 80px;
  display: none;
  z-index: 100;
}
.user-topinfo .ut-logout div {
  display: block;
  width: 85%;
  height: 34px;
  line-height: 34px;
  border-radius: 10px;
  padding-left: 5px;
  font-size: 12px;
  color: #111880;
  background: #ffffff;
  margin-bottom: 3px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
}
.user-topinfo .ut-logout div:nth-child(1) {
  margin-top: 5px;
}

.user-topinfo .ut-logout div:hover {
  color: #5473ff;
  box-shadow: 0px 2px 20px 0px rgba(59, 93, 245, 0.5);
  cursor: pointer;
}
</style>
